<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <!-- <b-navbar-brand href="#">ADMIN</b-navbar-brand>-->

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#" to="/agenda" ><span style="color:white">CALENDARIO</span></b-nav-item>
        <!-- <b-nav-item href="#" to="/vendite" ><span style="color:white">VENDITE</span></b-nav-item>-->

        <!-- <b-nav-item href="#" to="/adminProducts"><span style="color:white">PRODOTTI</span></b-nav-item>-->
        <b-nav-item-dropdown href="#" to="/adminProducts">
          <template #button-content>
            <span @click="vai('/adminProducts')" ><span style="color:white">PRODOTTI</span></span>
          </template>
          <b-dropdown-item href="#" to="/adminCategories">CATEGORIE</b-dropdown-item>
          <b-dropdown-item href="#" to="/gestioneVetrine">VETRINE</b-dropdown-item>

        </b-nav-item-dropdown>

        <!--     <b-nav-item href="#" to="/diamanti"><span style="color:white">DIAMANTI</span></b-nav-item> -->
        <b-nav-item-dropdown href="#">
          <template #button-content>
            <span><span style="color:white">PIETRE</span></span>
          </template>
          <b-dropdown-item href="#" to="/diamanti/TUTTO">Diamanti</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/diamanti_brown/TUTTO">Diamanti Brown</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/diamanti_multicolor/TUTTO">Diamanti Multicolor</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/diamanti_neri/TUTTO">Diamanti Neri</b-dropdown-item>

          <b-dropdown-item href="#" to="/pietre/h20/TUTTO">Acqua Marina</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/perle/TUTTO">Perle</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/quarzo/TUTTO">Quarzo</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/rubini/TUTTO">Rubini</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/smeraldi/TUTTO">Smeraldi</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/topazio/TUTTO">Topazio</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/tanzanite/TUTTO">Tanzanite</b-dropdown-item>

          <b-dropdown-item href="#" to="/pietre/zaffiri/TUTTO">Zaffiri</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/zaffiri_multicolor/TUTTO">Zaffiri Multicolor</b-dropdown-item>
          <b-dropdown-item href="#" to="/pietre/altre/TUTTO">Altre Pietre</b-dropdown-item>







        <!--  <b-dropdown-item href="#" to="/diamanti/MAGAZZINO">A MAGAZZINO</b-dropdown-item>
          <b-dropdown-item href="#" to="/diamanti/NONDISPONIBILI">NON DISPONIBILI</b-dropdown-item> -->
        </b-nav-item-dropdown>

        <!--   <b-nav-item href="#" to="/adminCategories"><span style="color:white">CATEGORIE</span></b-nav-item>-->
        <!--   <b-nav-item href="#" to="/riparazioni"><span style="color:white">LAVORAZIONI</span></b-nav-item>-->

        <b-nav-item-dropdown href="#">
          <template #button-content>
            <span @click="vai('/riparazioni/TUTTO')" ><span style="color:white">LAVORAZIONI</span></span>
          </template>
          <b-dropdown-item href="#" to="/riparazioni/ORDINE">ORDINI</b-dropdown-item>
          <b-dropdown-item href="#" to="/riparazioni/RIPARAZIONE">RIPARAZIONI</b-dropdown-item>
          <b-dropdown-item href="#" to="/riparazioni/VALUTAZIONE">VALUTAZIONI</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item href="#" to="/laboratorio" ><span style="color:white">LAB</span></b-nav-item>

        <!--   <b-nav-item href="#" to="/clienti"><span style="color:white">CLIENTI</span></b-nav-item> -->
        <b-nav-item-dropdown href="#">
          <template #button-content>
            <span @click="vai('/clienti/NO')" ><span style="color:white">CLIENTI</span></span>
          </template>
          <b-dropdown-item href="#" to="/clienti/PROMEMORIA">PROMEMORIA</b-dropdown-item>
          <b-dropdown-item v-if="this.$session.exists() &&  (this.$session.get('AUTORIZZAZIONI').elenco_visite_vendite >= 1)"  href="#" to="/statsVV">VISITE & VENDITE</b-dropdown-item>
          <b-dropdown-item href="#" to="/conti">CONTI</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-navbar-brand   to="/note"><span class="itemNB"><b-icon  style="margin-left:1em"  icon="pencil-square"  font-scale="1.5" ></b-icon></span></b-navbar-brand>
        <b-navbar-brand   to="/notifiche">
          <span v-if="nNotifiche>0" class="itemNB badgecontainer">
            <b-icon  style="margin-left:1em"  icon="chat"  font-scale="1.5" ></b-icon>
            <span  class="badge badge-pill badge-notifiche">{{nNotifiche}}</span>
        </span>
        <span v-else class="itemNB badgecontainer">
          <b-icon  style="margin-left:1em"  icon="chat"  font-scale="1.5" ></b-icon>
      </span>
      </b-navbar-brand>


        <!--
        <b-nav-item href="#" to="/statsVV"><span style="color:white">VISITE & VENDITE</span></b-nav-item>
        <b-nav-item href="#" to="/conti"><span style="color:white">CONTI</span></b-nav-item>-->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-navbar-brand   to="/"><b-icon  icon="shop"  font-scale="1.5" style="margin-left:2em;"></b-icon></b-navbar-brand>

        <b-nav-item right v-if="this.$session.exists() &&  (this.$session.get('AUTORIZZAZIONI').db == 2)" href="#" to="/gestioneDB"><span style="color:black"><b-icon  icon="tools"  font-scale="1.5" style="margin-left:2em;"></b-icon></span></b-nav-item>
        <b-nav-item right v-if="this.$session.exists()" href="#" to="/logout"><span style="color:black"><b-icon  icon="door-closed"  font-scale="1.5" style="margin-left:0em;"></b-icon>{{username}}</span></b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>


<script>
import axios from "axios";

export default {
  props: ['aggiornaIconaNotifiche'],
  data() {
    return {
      username:'',
      hrefBackup:'',
      nNotifiche:0,
    };
  },
  mounted() {
    this.$session.set('tipo', 'BE');
    this.hrefBackup=this.$store.state.settings.URL_SERVER+"API/autobackup.php";
    this.getNumeroNotifiche();
    this.username=this.$session.get('USR')
  },
  methods: {
    vai(url){
      this.$router.push({ path: url });
    },
    getNumeroNotifiche(){
      axios
      .get(this.$store.state.settings.URL_SERVER+"API/v1.php?numeroNotifiche&secret="+this.$session.get('secret'))
      .then(response => {
        console.log(response)
        this.nNotifiche = response.data.numeroNotifiche;
      })
      .catch(err => {
        console.log(err);
      });
    }
  },
  watch: {
    aggiornaIconaNotifiche: function(newVal, oldVal) {
      console.log(newVal + oldVal)
      this.getNumeroNotifiche()
    }
}
};
</script>
<style>
.fa-stack{

}

</style>
