import { render, staticRenderFns } from "./LoginAdm.vue?vue&type=template&id=e10d75aa&"
import script from "./LoginAdm.vue?vue&type=script&lang=js&"
export * from "./LoginAdm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports