<template>
  <div style="">
    <b-navbar class="navmenu navbar-custom" toggleable="lg" > <b-img  :src="require('../assets/soloLogo_b.png')"  fluid alt="Responsive image" width=30></b-img>

      <b-container>
        <b-navbar-brand class="navitem-custom" to="/"><span class="itemNB">HOME</span></b-navbar-brand>
        <b-navbar-brand  v-if="$route.params.livello>1" @click="$router.go(-1)"><span class="itemNB">INDIETRO</span></b-navbar-brand>
        <b-navbar-brand  v-if="$route.name=='search'" @click="$router.go(-1)"><span class="itemNB">INDIETRO</span></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>

              <!--<b-navbar-brand   to="/vetrina"><span class="itemNB">Vetrina</span></b-navbar-brand>-->
            <!--  <b-navbar-brand   to="/diamanti/TUTTO"><span class="itemNB"><b-icon style="margin-right:1em"  icon="gem"  font-scale="1.3" ></b-icon></span></b-navbar-brand> -->
              <b-nav-item-dropdown href="#">
                    <template #button-content>
                      <span><span class="itemNB"><b-icon style="margin-right:1em"  icon="gem"  font-scale="1.3" ></b-icon></span></span>
                    </template>
                  <b-dropdown-item href="#" to="/diamanti/TUTTO">Diamanti</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/diamanti_brown/TUTTO">Diamanti Brown</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/diamanti_multicolor/TUTTO">Diamanti Multicolor</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/diamanti_neri/TUTTO">Diamanti Neri</b-dropdown-item>

                  <b-dropdown-item href="#" to="/pietre/h20/TUTTO">Acqua Marina</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/perle/TUTTO">Perle</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/quarzo/TUTTO">Quarzo</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/rubini/TUTTO">Rubini</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/smeraldi/TUTTO">Smeraldi</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/topazio/TUTTO">Topazio</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/tanzanite/TUTTO">Tanzanite</b-dropdown-item>

                  <b-dropdown-item href="#" to="/pietre/zaffiri/TUTTO">Zaffiri</b-dropdown-item>
                  <b-dropdown-item href="#" to="/pietre/altre/TUTTO">Altre Pietre</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-navbar-brand   to="/favoriti"><span class="itemNB"><b-icon  style="margin-right:1em"  icon="heart-fill"  font-scale="1.3" ></b-icon></span></b-navbar-brand>
              <b-navbar-brand   to="/note"><span class="itemNB"><b-icon  style="margin-right:1em"  icon="pencil-square"  font-scale="1.3" ></b-icon></span></b-navbar-brand>
              <b-navbar-brand   to="/nuoviarrivi"><span class="itemNB"><b-icon  icon="clock"  font-scale="1.3" ></b-icon></span></b-navbar-brand>

              <b-button v-b-modal.modal-filtro size="sm" class=""><span class="buttonNB">Imposta Filtri{{this.altriFiltri}}</span></b-button>
              <div class="ml-1 mr-1">{{this.prezzoTxt}} <span v-if="this.prezzoTxt!=''" ><br></span>{{this.carTxt}}</div>
              <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Cerca un prodotto"
              v-model="prodotto"
              ></b-form-input>
              <b-button
              size="sm"
              class="my-1 my-sm-0 buttonNB"
              @click="cercaProdotto(prodotto)"
              ><span size="sm" class="buttonNB">Cerca</span></b-button>
            </b-nav-form>

          </b-navbar-nav>
          <b-navbar-brand   to="/agenda"><b-icon  icon="gear-fill"  font-scale="1.3" style="margin-left:2em;"></b-icon></b-navbar-brand>
          <b-navbar-brand class="navitem-custom"><span class="itemNB"><VueInputCalculator enableKeyboard v-model="calc"><b-icon  style="margin-right:1em"  icon="calculator"  font-scale="1.3" ></b-icon></VueInputCalculator></span></b-navbar-brand>
          <b-navbar-brand  v-if="goldPrice">{{goldPrice}} €/gr</b-navbar-brand>


        </b-collapse>
      </b-container>

    </b-navbar>




    <b-modal id="modal-filtro" size="xl" class="modal-filtro" hide-footer :title="'IMPOSTA FILTRI'" button-size="lg" @hide="chiusuraModale">
      <b-form>

        Prezzo
        <div class="btn-group btn-group-toggle flex-wrap mr-1 ml-2" data-toggle="buttons">
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva0 }">
            <input type="radio" name="options" id="fascia0" autocomplete="off" @click="selezionaFasciaPrezzo(0)">Tutto
          </label>
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva1 }">
            <input type="radio" name="options" id="fascia1" autocomplete="off" @click="selezionaFasciaPrezzo(1)">&lt;500 €
          </label>
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva2 }">
            <input type="radio" name="options" id="fascia2" autocomplete="off" @click="selezionaFasciaPrezzo(2)" >500-1000
          </label>
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva3 }">
            <input type="radio" name="options" id="fascia3" autocomplete="off" @click="selezionaFasciaPrezzo(3)">1000-2000
          </label>
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva4 }">
            <input type="radio" name="options" id="fascia4" autocomplete="off" @click="selezionaFasciaPrezzo(4)">2000-3000
          </label>
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva5 }">
            <input type="radio" name="options" id="fascia5" autocomplete="off" @click="selezionaFasciaPrezzo(5)">3000-5000
          </label>
          <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva6 }" >
            <input type="radio" name="options" id="fascia6" autocomplete="off" @click="selezionaFasciaPrezzo(6)">&gt;5000
          </label>
        </div>

        <br><br>
        Carati

        <div class="btn-group btn-group-toggle flex-wrap mr-1 ml-2" data-toggle="buttons">
          <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva0 }">
            <input type="radio" name="options_ct" id="fascia0_ct" autocomplete="off" @click="selezionaFasciaCt(0)">Tutto
          </label>
          <!--  <label class="btn btn-outline-primary"  v-bind:class="{ active: fasciaAttiva1 }">
          <input type="radio" name="options_ct" id="fascia1_ct" autocomplete="off" @click="selezionaFasciaCt(1)">&lt;500 €
        </label>-->
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva2 }">
          <input type="radio" name="options_ct" id="fascia2_ct" autocomplete="off" @click="selezionaFasciaCt(2)">0,10-0,30
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva3 }">
          <input type="radio" name="options_ct" id="fascia3_ct" autocomplete="off" @click="selezionaFasciaCt(3)">0,30-0,50
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva4 }">
          <input type="radio" name="options_ct" id="fascia4_ct" autocomplete="off" @click="selezionaFasciaCt(4)">0,50-0,70
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva5 }">
          <input type="radio" name="options_ct" id="fascia5_ct" autocomplete="off" @click="selezionaFasciaCt(5)">0,70-0,90
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva6 }">
          <input type="radio" name="options_ct" id="fascia6_ct" autocomplete="off" @click="selezionaFasciaCt(6)">0,90 -1,00
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva7 }">
          <input type="radio" name="options_ct" id="fascia7_ct" autocomplete="off" @click="selezionaFasciaCt(7)">1,00 - 1,50
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: ct_fasciaAttiva8 }" >
          <input type="radio" name="options_ct" id="fascia8_ct" autocomplete="off" @click="selezionaFasciaCt(8)">&gt;1,50
        </label>
      </div>

      <br><br>
      Collezioni
      <br>

      <div class="btn-group btn-group-toggle flex-wrap mr-1 ml-2" data-toggle="buttons">
        <label class="btn btn-outline-primary"  v-bind:class="{ active: classiciTutto }">
          <input type="checkbox" name="options_classici_o_classici_colorati" id="classiciTutto"  @click="filtroClassici('tutto')">Tutto
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: classici }">
          <input type="checkbox" name="options_classici_o_classici_colorati" id="classici"  @click="filtroClassici('classici')">CLASSICI
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: classiciColorati }">
          <input type="checkbox" name="options_classici_o_classici_colorati" id="classici_colorati" @click="filtroClassici('classiciColorati')">CLASSICI COLORATI
        </label>
      </div>
      <br><br>

      <div class="btn-group btn-group-toggle flex-wrap mr-1 ml-2" data-toggle="buttons">
        <label class="btn btn-outline-primary"  v-bind:class="{ active: FtipoTutto }">
          <input type="radio" name="options_categoria2" id="anelli" autocomplete="off" @click="filtroFTipo('tutto')">Tutto
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: Fanelli }">
          <input type="radio" name="options_categoria2" id="anelli" autocomplete="off" @click="filtroFTipo('anelli')">ANELLI
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: Fbraccialetti }">
          <input type="radio" name="options_categoria2" id="braccialetti" autocomplete="off" @click="filtroFTipo('braccialetti')">BRACCIALETTI
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: Fciondoli }">
          <input type="radio" name="options_categoria2" id="ciondoli" autocomplete="off" @click="filtroFTipo('ciondoli')">CIONDOLI
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: Forecchini }">
          <input type="radio" name="options_categoria2" id="orecchini" autocomplete="off" @click="filtroFTipo('orecchini')" >ORECCHINI
        </label>
      </div>


      <br><br>
      Materiale
      <div class="btn-group btn-group-toggle  flex-wrap mr-1 ml-2" data-toggle="buttons">
        <label class="btn btn-outline-primary"  v-bind:class="{ active: oroTutto }">
          <input type="checkbox" name="options_materiale" id="oroBianco"  @click="selezionaMateriale('tutto')">Tutto
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: oroBianco }">
          <input type="checkbox" name="options_materiale" id="oroBianco"  @click="selezionaMateriale('oroBianco')">Oro Bianco
        </label>
        <label class="btn btn-outline-primary"  v-bind:class="{ active: oroRosa }">
          <input type="checkbox" name="options_materiale" id="oroRosa" @click="selezionaMateriale('oroRosa')">Oro Rosa
        </label>
      </div>

      <br><br>
      <b-button @click="$bvModal.hide('modal-filtro')">Chiudi</b-button>
      <b-button variant="warning"  @click="resetFiltri()" style="margin-left:1em">Reset</b-button>

      <b-button @click="cercaProdotto(prodotto)" style="float:right" variant="success">Cerca</b-button>

    </b-form>
  </b-modal>


</div>
</template>
<script>
import axios from "axios";
import VueInputCalculator from "vue-input-calculator";

export default {
  props: ['idCategoria'],
components: {VueInputCalculator},
  data() {
    return {
      goldPrice:null,
      calc:0,
      prodotto: '',
      prezzoTxt:'',
      carTxt:'',
      altriFiltri:'',
      carDa:0,
      carA:0,
      carNO:true,
      fascia:0,
      fasciap1:0,
      fasciap2:0,
      fasciaAttiva0:true,
      fasciaAttiva1:false,
      fasciaAttiva2:false,
      fasciaAttiva3:false,
      fasciaAttiva4:false,
      fasciaAttiva5:false,
      fasciaAttiva6:false,
      fasciact1:0,
      fasciact2:0,
      ct_fasciaAttiva0:true,
      ct_fasciaAttiva1:false,
      ct_fasciaAttiva2:false,
      ct_fasciaAttiva3:false,
      ct_fasciaAttiva4:false,
      ct_fasciaAttiva5:false,
      ct_fasciaAttiva6:false,
      ct_fasciaAttiva7:false,
      ct_fasciaAttiva8:false,
      classiciTutto:true,
      classici:false,
      classiciColorati:false,
      Fanelli:false,
      Fbraccialetti:false,
      Fciondoli:false,
      Forecchini:false,
      FtipoTutto:true,
      oroTutto:true,
      oroRosa:false,
      oroBianco:false,
      catAttuale:0,
      filtroTipoClassici:'tutto',
      filtroTipoTipo:'tutto',
      filtroTipoMateriale:'tutto',

      maxCT:0,
    }
  },
  mounted() {
    this.$session.set('tipo', 'FE');

    this.getGoldPrice();
    this.getMaxCT();
    if(this.idCategoria!=undefined){
      this.catAttuale=this.idCategoria
    }else{
      if(this.$route.params.catAttuale>0){
        this.catAttuale=this.$route.params.catAttuale
      }
    }

    if(this.$route.params.txt!=undefined){
      this.cerca=this.$route.params.txt;
    }
    if(this.$route.params.fasciap1!=undefined){
      this.fasciap1=this.$route.params.fasciap1;
    }
    if(this.$route.params.fasciap2!=undefined){
      this.fasciap2=this.$route.params.fasciap2;
    }
    if(this.$route.params.fasciact1!=undefined){
      this.fasciact1=this.$route.params.fasciact1;
    }
    if(this.$route.params.fasciact2!=undefined){
      this.fasciact2=this.$route.params.fasciact2;
    }
    if(this.$route.params.filtroTipoClassici!=undefined){
      this.classiciTutto=false
      this.classici=false
      this.classiciColorati=false
      this.filtroTipoClassici=this.$route.params.filtroTipoClassici;
      switch (this.filtroTipoClassici) {
        case 'C':
        this.classici=true;
        break;
        case 'CC':
        this.classiciColorati=true;
        break;
        default:
        this.classiciTutto=true;
      }
    }
    if(this.$route.params.filtroTipoTipo!=undefined){
      this.filtroTipoTipo=this.$route.params.filtroTipoTipo;
      this.Fanelli=false
      this.Fbraccialetti=false
      this.Fciondoli=false
      this.Forecchini=false
      this.FtipoTutto=false
      switch (this.filtroTipoTipo) {
        case 'anelli':
        this.Fanelli=true;
        break;
        case 'braccialetti':
        this.Fbraccialetti=true;
        break;
        case 'ciondoli':
        this.Fciondoli=true;
        break;
        case 'orecchini':
        this.Forecchini=true;
        break;
        default:
        this.FtipoTutto=true;
      }
    }
    if(this.$route.params.filtroTipoMateriale!=undefined){
      this.filtroTipoMateriale=this.$route.params.filtroTipoMateriale;
      this.oroBianco=false
      this.oroRosa=false
      this.oroTutto=false
      switch (this.filtroTipoMateriale) {
        case 'OB':
        this.oroBianco=true;
        break;
        case 'OR':
        this.oroRosa=true;
        break;
        default:
        this.oroTutto=true;
      }
    }
    if(this.$route.params.catAttuale!=undefined){
      this.catAttuale=this.$route.params.catAttuale;
    }
    this.isFiltriAttivi();

  },
  methods: {
    chiusuraModale(){
      this.isFiltriAttivi();
    },
    resetFiltri(){
      this.carTxt='',
      this.altriFiltri='',
      this.fascia=0
      this.fasciap1=0
      this.fasciap2=0
      this.fasciaAttiva0=true
      this.fasciaAttiva1=false
      this.fasciaAttiva3=false
      this.fasciaAttiva2=false
      this.fasciaAttiva4=false
      this.fasciaAttiva5=false
      this.fasciaAttiva6=false
      this.fasciact2=0
      this.fasciact1=0
      this.ct_fasciaAttiva0=true
      this.ct_fasciaAttiva1=false
      this.ct_fasciaAttiva3=false
      this.ct_fasciaAttiva2=false
      this.ct_fasciaAttiva4=false
      this.ct_fasciaAttiva5=false
      this.ct_fasciaAttiva6=false
      this.ct_fasciaAttiva7=false
      this.ct_fasciaAttiva8=false
      this.classiciTutto=true
      this.classiciColorati=false
      this.classici=false
      this.Fanelli=false
      this.Fbraccialetti=false
      this.Fciondoli=false
      this.Forecchini=false
      this.FtipoTutto=true
      this.oroTutto=true
      this.oroRosa=false
      this.oroBianco=false
    },
    isFiltriAttivi(){

      let nFiltri=0;

      if(this.classici || this.classiciColorati) nFiltri++;
      //  if(this.anelli || this.braccialetti || this.ciondoli ) nFiltri++;
      if(this.oroRosa || this.oroBianco) nFiltri++;
      if((this.fasciap1>0) || (this.fasciap2>0)) nFiltri++;
      if((this.fasciact1>0) || (this.fasciact2>0)) nFiltri++;



      if(nFiltri>0){
        this.altriFiltri= "("+nFiltri+")";
      }else{
        this.altriFiltri= "";
      }

    },
    getGoldPrice(){
      axios
      .get(this.$store.state.settings.URL_SERVER+"API/v1.php?sole24Gold")
      .then(response => {
        if (response.status === 200 && response.data.stato=="OK") {
          this.goldPrice = response.data.prezzo;
        }else{
          this.goldPrice=null;
        }
      })
      .catch(err => {
        console.log(err);
      });
    },
    getMaxCT(){
      axios
      .get(this.$store.state.settings.URL_SERVER+"API/v1.php?maxCT")
      .then(response => {
        this.maxCT = response.data.maxCT;
      })
      .catch(err => {
        console.log(err);
      });
    },
    cercaProdotto(p) {

      if(p==''){
        p="ALLPRODUCTS";
      }
      //  this.$router.replace({ name: 'search', params: { txt:p,fascia:this.fascia,carDa:this.carDa,carA:this.carA }, force: true});
    /*  this.$router.replace({ name: 'search', params: { txt:p,fasciap1:this.fasciap1,fasciap2:this.fasciap2,fasciact1:this.fasciact1,fasciact2:this.fasciact2,
        classici:this.classici,classiciColorati:this.classiciColorati,
        anelli:this.Fanelli,braccialetti:this.Fbraccialetti,ciondoli:this.Fciondoli,orecchini:this.Forecchini,
        oroRosa:this.oroRosa,oroBianco:this.oroBianco,catAttuale:this.catAttuale }, force: true});*/

        this.$router.replace({ name: 'search', params: { txt:p,fasciap1:this.fasciap1,fasciap2:this.fasciap2,fasciact1:this.fasciact1,fasciact2:this.fasciact2,
            filtroTipoClassici:this.filtroTipoClassici,filtroTipoTipo:this.filtroTipoTipo,
            filtroTipoMateriale:this.filtroTipoMateriale,catAttuale:this.catAttuale }, force: true});
        //this.$bvModal.hide('modal-filtro')
      },
      filtroClassici(t){
        this.classici = false;
        this.classiciColorati = false;
        this.classiciTutto = false;
        if(t=='tutto'){
          this.classiciTutto = true;
          this.filtroTipoClassici = 'tutto';
        }
        if(t=='classici'){
          this.classici = true;
          this.filtroTipoClassici = 'C';
        }
        if(t=='classiciColorati'){
          this.classiciColorati = true;
          this.filtroTipoClassici = 'CC';
        }
      },
      selezionaMateriale(t){
        this.oroBianco = false;
        this.oroRosa = false;
        this.oroTutto = false;
        if(t=='tutto'){
          this.oroTutto = true;
          this.filtroTipoMateriale='tutto'
        }
        if(t=='oroBianco'){
          this.oroBianco = true;
          this.filtroTipoMateriale='OB'
        }
        if(t=='oroRosa'){
          this.oroRosa = true;
          this.filtroTipoMateriale='OR'
        }
      },
      filtroFTipo(t){
        this.Fanelli = false;
        this.Fbraccialetti = false;
        this.Fciondoli = false;
        this.Forecchini = false;
        this.FtipoTutto = false;

        if(t=='tutto'){
          this.FtipoTutto = true;
          this.filtroTipoTipo='tutto'
        }
        if(t=='anelli'){
          this.Fanelli = true;
          this.filtroTipoTipo='anelli'
        }
        if(t=='braccialetti'){
          this.Fbraccialetti = true;
          this.filtroTipoTipo='braccialetti'
        }
        if(t=='ciondoli'){
          this.Fciondoli = true;
          this.filtroTipoTipo='ciondoli'
        }
        if(t=='orecchini'){
          this.Forecchini = true;
          this.filtroTipoTipo='orecchini'
        }
      },
      selezionaFasciaPrezzo(idFascia){
        this.fasciap1=idFascia;
        this.fasciaAttiva0=false;

        this.fasciaAttiva0=false;
        this.fasciaAttiva1=false;
        this.fasciaAttiva2=false;
        this.fasciaAttiva3=false;
        this.fasciaAttiva4=false;
        this.fasciaAttiva5=false;
        this.fasciaAttiva6=false;
        this.prezzoTxt='Prezzo: ';
        switch (this.fasciap1) {
          case 0:
          this.fasciaAttiva0=true;
          this.prezzoTxt='';
          break;
          case 1:
          this.fasciaAttiva1=true;
          this.prezzoTxt=  this.prezzoTxt+" <500€"
          break;
          case 2:
          this.fasciaAttiva2=true;
          this.prezzoTxt=  this.prezzoTxt+" 500-1000€"
          break;
          case 3:
          this.fasciaAttiva3=true;
          this.prezzoTxt=  this.prezzoTxt+" 1000-2000€"
          break;
          case 4:
          this.fasciaAttiva4=true;
          this.prezzoTxt=  this.prezzoTxt+" 2000-3000€"
          break;
          case 5:
          this.fasciaAttiva5=true;
          this.prezzoTxt=  this.prezzoTxt+" 3000-6000€"
          break;
          case 6:
          this.fasciaAttiva6=true;
          this.prezzoTxt=  this.prezzoTxt+" >6000€"
          break;
        }
      },
      selezionaFasciaCt(idFascia){
        this.fasciact1=idFascia;
        this.ct_fasciaAttiva0=false;
        this.ct_fasciaAttiva1=false;
        this.ct_fasciaAttiva2=false;
        this.ct_fasciaAttiva3=false;
        this.ct_fasciaAttiva4=false;
        this.ct_fasciaAttiva5=false;
        this.ct_fasciaAttiva6=false;
        this.carTxt='Ct: ';
        switch (this.fasciact1) {
          case 0:
          this.ct_fasciaAttiva0=true;
          this.carTxt='';
          break;
          case 1:
          this.ct_fasciaAttiva1=true;
          this.carTxt=  this.carTxt+" ---"
          break;
          case 2:
          this.ct_fasciaAttiva2=true;
          this.carTxt=  this.carTxt+" 0,10-0,30"
          break;
          case 3:
          this.ct_fasciaAttiva3=true;
          this.carTxt=  this.carTxt+" 0,30-0,50"
          break;
          case 4:
          this.ct_fasciaAttiva4=true;
          this.carTxt=  this.carTxt+" 0,60-0,70"
          break;
          case 5:
          this.ct_fasciaAttiva5=true;
          this.carTxt=  this.carTxt+" 0,70-0,90"
          break;
          case 6:
          this.ct_fasciaAttiva6=true;
          this.carTxt=  this.carTxt+" 0,90 -1,00"
          break;
          case 7:
          this.ct_fasciaAttiva7=true;
          this.carTxt=  this.carTxt+" 1,00 - 1,50"
          break;
          case 8:
          this.ct_fasciaAttiva8=true;
          this.carTxt=  this.carTxt+" > 1,50"
          break;
        }

      },
      disattivaCar(oldValue){
        if(!oldValue){
          this.carDa=0.0;
          this.carA=0.0;
          this.carTxt="";
        }
      },
      modifcaCar(){
        this.carNO=false;
        if((this.carDa!=0) || (this.carA!=0)){
          this.carTxt="Carati: Da "+this.carDa+ " a "+this.carA;
        }else{
          this.carTxt="";
        }
      }
    }
  }
  </script>
